//  Initialize a global variables at root level.
//    Dark
$color-dark: #203C56;
$color-darker: #0D2B45;
//    Light
$color-light: #FFECD6;
$color-light-darker: #FFD4A3;
//    Primary
$color-primary: #FFAA5E;
$color-primary-lighter: #FFCB9A;
$color-primary-lightest: #FFD5AC;
$color-primary-dark: #D08159;
$color-primary-darker: #966753;
$color-primary-darkest: #6D554F;
//    Accent
$color-accent: #8D697A;
$color-accent-lighter: #D7BFB6;
$color-accent-lightest: #C6ABA8;
$color-accent-dark: #544E68;
$color-accent-darker: #3F445E;
$color-accent-darkest: #303D57;
//    Light Accent
$color-lightaccent: #929895;
$color-lightaccent-lighter: #D9CFC0;
$color-lightaccent-lightest: #C9C2B6;
$color-lightaccent-dark: #50626D;
$color-lightaccent-darker: #3C5261;
$color-lightaccent-darkest: #2E4659;
//    Warning
$color-warning: #FC9F1C;
$color-warning-lighter: #FEC679;
$color-warning-lightest: #FED195;
$color-warning-darker: #856531;
$color-warning-darkest: #615437;
//    Danger
$color-danger: #F44336;
$color-danger-lighter: #FA9886;
$color-danger-lightest: #FCB19E;
$color-danger-darker: #81373E;
$color-danger-darkest: #5E3340;
//    Info
$color-info: #142130;
$color-info-lighter: #8A8783;
$color-info-lightest: #ADA59C;
$color-info-darker: #11263B;
$color-info-darkest: #10283E;
//    Success
$color-success: #7FAF54;
$color-success-lighter: #BFCE95;
$color-success-lightest: #D2D7A9;
$color-success-darker: #466D4D;
$color-success-darkest: #35594B;

@import 'styles/colors';
@import 'styles/components';
@import url('https://fonts.googleapis.com/css2?family=PT+Mono&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');


/* You can add global styles to this file, and also import other style files */
*, *:before, *:after {
  box-sizing: border-box;
}

*:focus {
  outline: none !important
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8D697A;
  opacity: 1; /* Firefox */
}

input, button {
  padding: 5px 10px 5px 10px;
  border: none;
  border-radius: 0.25rem;
}

button {
  color: $color-primary;
}

button:disabled {
  color: $color-primary-dark;
  border-color: $color-primary-dark;
}

button:hover:not(:disabled) {
  color: $color-darker;
  background-color: $color-primary;
  border-color: $color-primary;
}

button:active:not(:disabled) {
  background-color: $color-primary-lighter;
  border-color: $color-primary-lighter;
}

input {
  border: 1px solid #544E68; // add only bottom border
  background-color: #0D2B45;
  color: $color-light;
}

input:hover, input:focus {
  border: 1px solid #8D697A; // add only bottom border
}

input[type=text] {
  // input elements with type="text" attribute
}

input[type=password] {
  // input elements with type="password" attribute
}

html {
  background-color: $color-darker;
  color: #FFECD6;
  font-family: 'PT Serif', serif;
}

button, input {
  font-family: 'PT Serif', serif;
}

button {
  background-color: transparent;
  color: #FFAA5E;
  border: 1px solid #FFAA5E;
}

a {
  color: inherit;
  text-decoration: none;
}

h2 {
  color: #544E68;
}

body {
  padding: 0;
  margin: 0;
}

ul {
  margin-block-start: 4px;
  margin-block-end: 4px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 24px;
}

ngneat-dialog {
  .ngneat-dialog-backdrop {
    background: var($color-darker, 0.50) !important;
    padding: 0;

    .ngneat-dialog-content {
      background: $color-dark !important;
      border: 12px solid;
      border-image: url(styles/border.png) 30%/1 stretch;

      .ngneat-drag-marker {
        // draggable marker
      }

      .ngneat-close-dialog {
        // 'X' icon for closing the dialog
      }
    }
  }
}

